body {
  margin: 0;
  font-family: 'Fira Code', 'Roboto', 'Helvetica', 'Arial', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	font-size: 16px;
	background: black;
	overscroll-behavior-y: contain;
}

* {
	box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas {
	display: block;
}

.cursor-pointer {
	cursor: pointer;
}

button, textarea, input {
	font-family: inherit;
}

.small {
	font-size: 0.8em;
}

h2 {
	margin: 0;
}

mark {
	background-color: black;
	color: white;
	padding: 5px;
}